// ログイン時の画面レンダリング

$(() => {
  $.ajax({
    type: 'get',
    url: '/api/is_auth',
    dataType: 'json'
  })
    .done(response => {
      console.log(response)
      if (response.status === 'OK' && response.data.is_auth === true) {
        const img = document.createElement('img')
        img.src = response.data.avatar
        $('.is_auth').show()
        if (document.URL.match(/user/)) {
          const url = document.URL;
          const pattern = /\/user\/(\w+)/;

          const match = url.match(pattern);
          if (match) {
            const extractedValue = match[1];
            if (response.data.id === extractedValue) {
              $('.is_my_page').show().append('(編集はこちら)')
            } else {
              $('.is_my_page').hide()
            }
          }
        }
        $('.is_auth p a').text(response.data.name + 'さん')
          .append(img)
        $('.is_auth .LittleMenu ul li a:first').attr('href', '/user/' + response.data.id)
        $('.is_not_auth').hide()
      } else {
        $('.is_auth').hide()
        $('.is_my_page').hide()
        $('.is_not_auth').show()
      }

      // 「id="jQueryBox"」を非表示
      $(".LittleMenu").css("display", "none");

      // 「id="jQueryPush"」がクリックされた場合
      $(".Login > p > a").click(function(){
        // 「id="jQueryBox"」の表示、非表示を切り替える
        $(".LittleMenu").toggle();
        return false
      });
    })
})
