/* ==========================================================
[ 目次 ]

<body>クラス設定
ハンバーガーメニュー
検索窓表示

========================================================== */

/* ---------------------------------------------
*   <body>クラス設定
--------------------------------------------- */
/**
 * <body>要素に、ユーザーのOS・デバイスとブラウザ等に関数情報をクラスとして付与する
 * OS・デバイス: ios, iphone, ipad, android, androidphone, androidtablet, windows, mac
 * ブラウザ: ie, edge, chrome, firefox, safari
 */
$(function() {
    'use strict';

    const ua = window.navigator.userAgent.toLowerCase();
    let bodyClasses = '';

    // プラットフォーム判定
    if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        bodyClasses += ' ios';

        if (ua.indexOf('iphone') > -1) {
            bodyClasses += ' iphone';
        } else if (ua.indexOf('ipad') > -1) {
            bodyClasses += ' ipad';
        }
    } else if (ua.indexOf('android') > -1) {
        bodyClasses += ' android';

        if (ua.indexOf('android') > -1) {
            bodyClasses += ' androidphone';
        } else {
            bodyClasses += ' androidtablet';
        }
    } else if (ua.indexOf('windows') > -1) {
        bodyClasses += ' windows';
    } else if (ua.indexOf('mac') > -1) {
        bodyClasses += ' mac';
    }

    // ブラウザ判定
    if (ua.indexOf('msie') !== -1 || ua.indexOf('trident') !== -1) {
        bodyClasses += ' ie';
        const version = window.navigator.appVersion.toLowerCase();
        if (version.indexOf("msie 10.") !== -1) {
            bodyClasses += ' ie10';
        } else if (version.indexOf("msie 11.") !== -1) {
            bodyClasses += ' ie11';
        }
    } else if (ua.indexOf('edge') > -1) {
        bodyClasses += ' edge';
    } else if (ua.indexOf('chrome') > -1) {
        bodyClasses += ' chrome';
    } else if (ua.indexOf('firefox') > -1) {
        bodyClasses += ' firefox';
    } else if (ua.indexOf('safari') > -1) {
        bodyClasses += ' safari';
    }

    $('body').addClass(bodyClasses);
});



/* ---------------------------------------------
*   ハンバーガーメニュー
--------------------------------------------- */
$(function() {
    'use strict';

    const $body = $('body');
    const $html = $('html');
    const $trigger = $('.js-menu-trigger');
    const $content = $('.js-drawer-menu');
    const $overlay = $('.js-menu-overlay');
    const openClass = 'js-menu-opened';

    $trigger.on('click.spmenu', () => {
        const isOpened = $body.hasClass(openClass);
        const contentHeight = window.innerHeight;
        const overlayAnimName = isOpened ? 'fadeOut' : 'fadeIn';
        const params = {
            duration: 500,
            easing: 'easeInOutQuart'
        };

        if (!$content.hasClass('velocity-animating')) {
            $body.toggleClass(openClass);
            $html.toggleClass(openClass);
            $overlay.velocity('stop').velocity(overlayAnimName, params);
            $content.css('height', `${contentHeight}px`);
        }

        return false;
    });
});


/* ---------------------------------------------
*   検索窓表示
--------------------------------------------- */
$(function() {
    'use strict';

    function searchAccordion($trigger, $content) {
        $trigger.off().on('click.searchAcc', function() {
            $trigger.toggleClass('opened');
            $content.slideToggle();
        });

        $(document).off().on('click', function(e) {
            if (!($(e.target).closest($trigger).length || $(e.target).closest($content).length)) {
                $trigger.removeClass('opened');
                $content.slideUp();
            }
        });
    }

    searchAccordion($('.js-search-acc-trigger'), $('.js-search-acc-content'));
    searchAccordion($('.js-search-acc-trigger-author'), $('.js-search-acc-content-author'));
});


/* ---------------------------------------------
*   タブ切り替え
--------------------------------------------- */
$(function() {
    const $btn = $('.js-tab-trigger');
    const $content = $('.js-tab-content')

    $btn.on('click', function() {
        const tagId = $(this).data('id');

        $btn.removeClass('current');
        $content.removeClass('current');
        $(this).addClass('current');
        $('#' + tagId).addClass('current');
    });
});

/* ---------------------------------------------
*   ドロップダウンメニュー
--------------------------------------------- */
$(function() {
    const $dropTrigger = $('.js-dropdown-trigger');
    const $dropContent = $('.js-dropdown-content');

    $dropTrigger.hover(
        function() {
            $(this).find($dropContent).addClass('open');
        }, function() {
            $(this).find($dropContent).removeClass('open');
        }
    );
});

/* ---------------------------------------------
*   スライダー
--------------------------------------------- */
$(function() {
    $('.js-slider-reviewer').slick({
        autoplay:true,
        autoplaySpeed:5000,
        slidesToShow:1,
        responsive: [
        {
            breakpoint: 751,
            settings: {
                arrows: false,
                dots:true,
                slidesToShow: 1
            }
        }
        ]
    });

    $('.js-slider-shop').slick({
        autoplay:true,
        autoplaySpeed:5000,
        slidesToShow:4,
        responsive: [
        {
            breakpoint: 751,
            settings: {
                arrows: false,
                dots:true,
                slidesToShow: 2
            }
        }
        ]
    });
});

/* ---------------------------------------------
*   Fix btn
--------------------------------------------- */
$(function() {
    const fixedBtn = $('.js-fix-btn');
    const showClass = 'show';

    $(window).scroll(function() {
        if ($(this).scrollTop() > 50) {
            fixedBtn.addClass(showClass);
        } else {
            fixedBtn.removeClass(showClass);
        }
    });
});

/* ---------------------------------------------
*   アコーディオン
--------------------------------------------- */
$(function() {
    $('.Accordion').click(function () {
        $(this).next('ul').slideToggle();
        $(this).toggleClass('Open');
    });
});