/*
 * moovooトップ画面
*/
const $ = jQuery
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../partials/imagesloaded.pkgd.min'
import '../partials/slick.min'

import '../partials/moovoo/lib/lib.js'
import '../partials/moovoo/common.js'
import '../partials/jq-rotate/jQueryRotate'

import '../partials/auth'
import lazyLoadImage from "../partials/lazyload-image";

$(document).ready(function () {
  lazyLoadImage.initModule();
});